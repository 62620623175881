<template>
  <div class="signature-pad">
    <img v-if="value.signature" :src="value.signature" style="width:300px;height:150px;display:block;background-color:white" />
    <div v-if="value.name" style="font-size:1.5em;font-weight:bold;margin:0 1em 0 1em;">{{ value.name }}</div>
    <div v-if="value.date" style="font-size:1em;font-weight:bold;margin:0 1em 1em 1.5em;">Signed: {{ stringFormatDate(value.date) }}</div>
    <v-dialog v-model="active" width="350">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" :color="errorText !== '' ? 'error' : buttonColor">
          <v-icon v-if="!!icon" :left="!!icon && label">{{ icon }}</v-icon>
          <span v-html="htmlLabel"></span>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
          <canvas ref="signatureRef" style="border:1px solid rgba(0,0,0,0.42);background-color:white;"></canvas>
          <v-text-field v-if="showEnterName" ref="nameRef" v-model="name" label="Enter Name" :rules="[ !!name || 'Name is required' ]"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="active = false">Cancel</v-btn>
          <v-btn text @click="save">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="clear">Clear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="errorText" style="color:red;margin:5px;font-size:1em">{{ errorText }}</div>
  </div>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import SignaturePad from 'signature_pad'
import { stringFormatDate } from '../../../helpers/formatters'

export default {
  props: {
    icon: String,
    text: String,
    label: {
      type: String,
      default: 'Enter signature below'
    },
    title: {
      type: String,
      default: 'Enter Signature Below'
    },
    showEnterName: {
      type: Boolean,
      default: true
    },
    buttonColor: {
      type: String,
      default: ''
    },
    value: {
      type: [ String, Object ],
      default: () => {
        return { signature: '', name: '', date: null }
      }
    },
    required: {
      type: Boolean,
      default: false
    },
    requiredText: String
  },
  setup (props, { root, emit }) {
    const active = ref(false)
    const signatureRef = ref(null)
    const signaturePad = ref(null)
    const name = ref('')
    const nameRef = ref(null)
    const htmlLabel = computed(() => {
      if (props.required && root.$store.state.forms.formSettings.asteriskOnRequired) {
        return props.label + '<span class="error--text fas fa-asterisk" style="font-size:.6em;transform:translateY(-.6em);margin-left:.2em"></span>'
      }
      return props.label
    })

    watch(active, () => {
      if (active.value) {
        signaturePad.value = new SignaturePad(signatureRef.value)
        if (props.value.signature !== '') signaturePad.value.fromDataURL(props.value.signature)
      }
    })

    watch(() => props.value, (val) => {
      if (props.showEnterName && typeof (value) === 'object' && 'name' in val) name.value = val.name
    })

    function save () {
      if (signaturePad.value.toData().length === 0) {
        alert('No signature was entered. Cannot save without a signature.')
        return
      }
      let obj = { signature: signaturePad.value.toDataURL(), date: new Date() }
      if (props.showEnterName) {
        if (name.value === '') {
          nameRef.value.focus()
          return
        }
        obj.name = name.value
      }
      errorText.value = ''
      emit('input', obj)
      active.value = false
    }

    function clear () {
      signaturePad.value.clear()
    }

    const errorText = ref('')
    function validate () {
      if (props.required && (props.value.signature == null || props.value.signature === '' || props.value.name == null || props.value.name === '')) {
        errorText.value = props.requiredText || 'Required'
        return false
      }
      return true
    }

    return {
      active,
      signatureRef,
      signaturePad,
      name,
      nameRef,
      htmlLabel,
      save,
      clear,
      errorText,
      validate,
      stringFormatDate
    }
  }
}
</script>
